/* tslint:disable */
/* eslint-disable */
/**
 * Roman Swagger
 * Roman - Wire Bots Proxy
 *
 * The version of the OpenAPI document: 1.15.0
 * Contact: dejan@wire.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists} from '../runtime';

/**
 *
 * @export
 * @interface UpdateService
 */
export interface UpdateService {
  /**
   *
   * @type {string}
   * @memberof UpdateService
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateService
   */
  url?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateService
   */
  avatar?: string;
}

export function UpdateServiceFromJSON(json: any): UpdateService {
  return UpdateServiceFromJSONTyped(json, false);
}

export function UpdateServiceFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateService {
  if ((json === undefined) || (json === null)) {
    return json;
  }
  return {

    'name': !exists(json, 'name') ? undefined : json['name'],
    'url': !exists(json, 'url') ? undefined : json['url'],
    'avatar': !exists(json, 'avatar') ? undefined : json['avatar'],
  };
}

export function UpdateServiceToJSON(value?: UpdateService | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {

    'name': value.name,
    'url': value.url,
    'avatar': value.avatar,
  };
}


