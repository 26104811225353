/* tslint:disable */
/* eslint-disable */
/**
 * Roman Swagger
 * Roman - Wire Bots Proxy
 *
 * The version of the OpenAPI document: 1.15.0
 * Contact: dejan@wire.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists} from '../runtime';

/**
 *
 * @export
 * @interface PostMessageResult
 */
export interface PostMessageResult {
  /**
   *
   * @type {string}
   * @memberof PostMessageResult
   */
  messageId?: string;
}

export function PostMessageResultFromJSON(json: any): PostMessageResult {
  return PostMessageResultFromJSONTyped(json, false);
}

export function PostMessageResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostMessageResult {
  if ((json === undefined) || (json === null)) {
    return json;
  }
  return {

    'messageId': !exists(json, 'messageId') ? undefined : json['messageId'],
  };
}

export function PostMessageResultToJSON(value?: PostMessageResult | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {

    'messageId': value.messageId,
  };
}


