/* tslint:disable */
/* eslint-disable */
/**
 * Roman Swagger
 * Roman - Wire Bots Proxy
 *
 * The version of the OpenAPI document: 1.15.0
 * Contact: dejan@wire.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists} from '../runtime';

/**
 *
 * @export
 * @interface Call
 */
export interface Call {
  /**
   *
   * @type {string}
   * @memberof Call
   */
  version?: string;
  /**
   *
   * @type {string}
   * @memberof Call
   */
  type?: string;
  /**
   *
   * @type {boolean}
   * @memberof Call
   */
  resp?: boolean;
  /**
   *
   * @type {string}
   * @memberof Call
   */
  sessid?: string;
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof Call
   */
  props?: { [key: string]: string; };
}

export function CallFromJSON(json: any): Call {
  return CallFromJSONTyped(json, false);
}

export function CallFromJSONTyped(json: any, ignoreDiscriminator: boolean): Call {
  if ((json === undefined) || (json === null)) {
    return json;
  }
  return {

    'version': !exists(json, 'version') ? undefined : json['version'],
    'type': !exists(json, 'type') ? undefined : json['type'],
    'resp': !exists(json, 'resp') ? undefined : json['resp'],
    'sessid': !exists(json, 'sessid') ? undefined : json['sessid'],
    'props': !exists(json, 'props') ? undefined : json['props'],
  };
}

export function CallToJSON(value?: Call | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {

    'version': value.version,
    'type': value.type,
    'resp': value.resp,
    'sessid': value.sessid,
    'props': value.props,
  };
}


