/* tslint:disable */
/* eslint-disable */
/**
 * Roman Swagger
 * Roman - Wire Bots Proxy
 *
 * The version of the OpenAPI document: 1.15.0
 * Contact: dejan@wire.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface Mention
 */
export interface Mention {
  /**
   *
   * @type {string}
   * @memberof Mention
   */
  userId: string;
  /**
   *
   * @type {number}
   * @memberof Mention
   */
  offset: number;
  /**
   *
   * @type {number}
   * @memberof Mention
   */
  length: number;
}

export function MentionFromJSON(json: any): Mention {
  return MentionFromJSONTyped(json, false);
}

export function MentionFromJSONTyped(json: any, ignoreDiscriminator: boolean): Mention {
  if ((json === undefined) || (json === null)) {
    return json;
  }
  return {

    'userId': json['userId'],
    'offset': json['offset'],
    'length': json['length'],
  };
}

export function MentionToJSON(value?: Mention | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {

    'userId': value.userId,
    'offset': value.offset,
    'length': value.length,
  };
}


