/* tslint:disable */
/* eslint-disable */
/**
 * Roman Swagger
 * Roman - Wire Bots Proxy
 *
 * The version of the OpenAPI document: 1.15.0
 * Contact: dejan@wire.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists} from '../runtime';

/**
 *
 * @export
 * @interface AssetMeta
 */
export interface AssetMeta {
  /**
   *
   * @type {string}
   * @memberof AssetMeta
   */
  assetId: string;
  /**
   *
   * @type {string}
   * @memberof AssetMeta
   */
  assetToken?: string;
  /**
   *
   * @type {string}
   * @memberof AssetMeta
   */
  sha256: string;
  /**
   *
   * @type {string}
   * @memberof AssetMeta
   */
  otrKey: string;
}

export function AssetMetaFromJSON(json: any): AssetMeta {
  return AssetMetaFromJSONTyped(json, false);
}

export function AssetMetaFromJSONTyped(json: any, ignoreDiscriminator: boolean): AssetMeta {
  if ((json === undefined) || (json === null)) {
    return json;
  }
  return {

    'assetId': json['assetId'],
    'assetToken': !exists(json, 'assetToken') ? undefined : json['assetToken'],
    'sha256': json['sha256'],
    'otrKey': json['otrKey'],
  };
}

export function AssetMetaToJSON(value?: AssetMeta | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {

    'assetId': value.assetId,
    'assetToken': value.assetToken,
    'sha256': value.sha256,
    'otrKey': value.otrKey,
  };
}


